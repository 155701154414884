<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		<!-- <Load v-if="isLoading"></Load> -->
		
		<header :style="cssVarColor">
			<h1>{{ isExhibitionView ? $t('temporary_exhibition') : $t('selected_routes') }}</h1>
		</header>
		
		<div v-if="!isLoading && routesError" class="empty-route">
			{{ $t('no_routes') }}
		</div>
		
		<carousel v-else-if="!isLoading"
		          ref="carousel"
		          class="carousel"
		          :paginationEnabled="false"
		          :perPage="1"
		          :loop="true"
		          :navigate-to="pageToGo"
		          @page-change="onPageChange">
			<slide v-for="route in carouselRoutes" :key="route.idRoute" class="route">
				<div class="container">  
          <img style="filter: none" class="bg-image" :src="getImageUrl(route.mainImageUrl)" alt="">
				</div>
				<main v-if="route.locale">
					<div class="title">
						<h2 :ref="'title-' + route.index" @click="readMoreTitle()">
							{{ route.locale.name }}
						</h2>
<!--						<div v-if="showReadMoreTitle" class="read-more">
							<span v-if="!isExpandedTitle" :ref="'read-more-title-' + route.index" @click="readMoreTitle()">{{ $t('read_more') }}</span>
							<span v-else :ref="'read-less-title-' + route.index" @click="readMoreTitle()">{{ $t('read_less') }}</span>
						</div>-->
					</div>
					
					<div class="subtitle">
						<h3 :ref="'subtitle-' + route.index" @click="readMoreSubtitle()">
							{{ route.locale.description }}
						</h3>
<!--						<div v-if="showReadMoreSubtitle" class="read-more">
							<span v-if="!isExpandedSubtitle" :ref="'read-more-subtitle-' + route.index" @click="readMoreSubtitle()">{{ $t('read_more') }}</span>
							<span v-else :ref="'read-less-subtitle-' + route.index" @click="readMoreSubtitle()">{{ $t('read_less') }}</span>
						</div>-->
					</div>
					
<!--					<div v-if="route.minutes" class="info">
						<i class="mm-icon mm-icon-time white"/>
						{{ ($t('duration')).replace('%1$d', route.minutes) }}
					</div>-->
<!--					<div v-if="route.artworkCount" class="info">
						<i class="mm-icon mm-icon-picture white"/>
						{{ ($t('artworks_included')).replace('%1$d', route.artworkCount) }}
					</div>-->
					
					<div class="audio-container relative">
						<audio-player v-if="route.index === index && route.locale.mainAudioUrl"
						              :key="route.idRoute"
						              class="audio"
						              :file="route.locale.mainAudioUrl"
						              :auto-play="false"
						              :hide-controls-until-play="true"/>
					</div>
				</main>
			</slide>
		</carousel>
		
		<footer :style="footerCSSVars">
			<div v-if="!isLoading && !routesError" class="route-buttons">
				<div class="dots">
					<div v-for="n in carouselRoutes.length"
					     :key="n" class="dot"
					     :class="{'active': index === n-1}"
					     @click="$refs.carousel.goToPage(n-1)"/>
				</div>
				<button v-if="currentRoute && currentRoute.steps && currentRoute.steps.length" class="button-start" :style="cssVarColor" @click="goToRoute()">
					{{ $t('start_route') }}
					<i class="mm-icon mm-icon-arrow-right big"/>
				</button>
			</div>
			<div class="footer-buttons">
				<button class="button-white" @click="goBack()">
					<i class="mm-icon mm-icon-arrow-back big"/>
				</button>
				<button v-if="museum.recognitionAvailable" class="button-recognition" @click="goToRecognition()">
					<img v-if="customization && customization.recognitionIconUrl" class="recognition-icon" :src="customization.recognitionIconUrl" alt="">
					<img v-else class="recognition-icon" src="@/assets/icons/pointplay.png" alt="">
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import AudioPlayer from "@/components/AudioPlayer";
import { Carousel, Slide } from 'vue-carousel';
import {setFullScreen} from "@/store/helpers";
import MuseumsAPI from "@/api/MuseumsAPI";
// import Load from '../../components/Load.vue';

export default {
	name: "RouteList",
	
	components: {
		AudioPlayer,
		// Load,
		Loading,
		Carousel,
		Slide
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			isExhibitionView: false,
			index: 0,
			pageToGo: 0,
			showReadMoreTitle: false,
			isExpandedTitle: false,
			showReadMoreSubtitle: false,
			isExpandedSubtitle: false,
			sectionExhibitions: null,
			sectionRoutes: null,
			keyboardCustomization: null,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			routes: state => state.route.routes,
			routesError: state => state.route.routesError,
			navigationSections: state => state.navigationSection.navigationSections,
			navigationSectionsError: state => state.navigationSection.navigationSectionsError,
			visit: state => state.app.visit,
			visitv2: state => state.app.visitv2
		}),
		
		carouselRoutes() {
			if(!(+this.$route.query.idRoute)) {
				return this.routes.filter(route => !route.highlighted);
			}

			return this.routes;
		},
		
		currentRoute() {
			return this.routes[this.index];
		},
		
		pageTitle() {
			let locationName = this.$i18n.t('selected_routes');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		cssVarColor() {
			let bgColor = null;
			let textColor = null;

			if(!this.isLoading) {
				bgColor = (this.isExhibitionView)
					? ((this.sectionExhibitions?.active && this.sectionExhibitions.lineColor) ? this.sectionExhibitions.lineColor : '#17779b')
					: ((this.sectionRoutes?.active && this.sectionRoutes.lineColor) ? this.sectionRoutes.lineColor : '#d1b490');
				
				textColor = (this.isExhibitionView)
					? ((this.sectionExhibitions?.active && this.sectionExhibitions.arrowColor) ? this.sectionExhibitions.arrowColor : '#ffffff')
					: ((this.sectionRoutes?.active && this.sectionRoutes.arrowColor) ? this.sectionRoutes.arrowColor : '#ffffff');
			}
			
			return {
				'--bg-color': bgColor,
				'--text-color': textColor
			};
		},
		
		footerCSSVars() {
			return {
				'--recognition-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.recognitionColor : '#f06140',
			};
		},
	},
	
	created() {
		this.isExhibitionView = this.$route.name.search('Exhibition') !== -1;
		debug.log("[" + (this.isExhibitionView ? "ExhibitionList" : "RouteList") + "] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			this.loadMuseum(this.idMuseum)
				.then(async () => {
					await this.getRoutes({ exhibitions: this.isExhibitionView, idRoute: +this.$route.query.idRoute });
					this.getNavigationSections();
					this.sectionExhibitions = this.navigationSections.find((s) => s.sectionType === 'EXHIBITIONS');
					this.sectionRoutes = this.navigationSections.find((s) => s.sectionType === 'ROUTES');
					if(this.museum.keyboardCustomization && this.museum.keyboardCustomization.active) {
						this.keyboardCustomization = this.museum.keyboardCustomization;
					}
				})
				.finally(async() => {
					await this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
					
					if(this.$route.query.fromRoute) {
						let route = this.routes.find((route) => route.idRoute === +this.$route.query.fromRoute);
						if(route) {
							this.index = parseInt(route.index);
							this.pageToGo = parseInt(route.index);
						}
					}
					
					this.shouldShowReadMore();
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	updated() {
		let wrapper = document.getElementsByClassName('VueCarousel-wrapper');
		if(wrapper.length) {
			wrapper.item(0).style.height = '100%';
		}
		let inner = document.getElementsByClassName('VueCarousel-inner');
		if(inner.length) {
			inner.item(0).style.height = '100%';
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('route', ['getRoutes']),
		...mapActions('navigationSection', ['getNavigationSections']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		onPageChange(index) {
			this.index = index;
			this.isExpandedTitle = false;
			this.isExpandedSubtitle = false;
			this.shouldShowReadMore();
		},
		
		shouldShowReadMore() {
			// 58 is the height of two lines of text in the title
			this.showReadMoreTitle = (this.$refs['title-' + this.index])
				? this.$refs['title-' + this.index][0]?.offsetHeight > 58
				: false;
			if(this.showReadMoreTitle) {
				this.$refs['title-' + this.index][0].classList.add('text-overflow');
			}
			
			// 81 is the height of three lines of text in the subtitle
			this.showReadMoreSubtitle = (this.$refs['subtitle-' + this.index])
				? this.$refs['subtitle-' + this.index][0]?.offsetHeight > 81
				: false;
			if(this.showReadMoreSubtitle) {
				this.$refs['subtitle-' + this.index][0].classList.add('text-overflow');
			}
		},
		
		readMoreTitle() {
			setFullScreen();
			this.isExpandedTitle = !this.isExpandedTitle;
			if(this.isExpandedTitle) {
				this.$refs['title-' + this.index][0].classList.remove('text-overflow');
			} else {
				this.$refs['title-' + this.index][0].classList.add('text-overflow');
			}
		},
		
		readMoreSubtitle() {
			setFullScreen();
			this.isExpandedSubtitle = !this.isExpandedSubtitle;
			if(this.isExpandedSubtitle) {
				this.$refs['subtitle-' + this.index][0].classList.remove('text-overflow');
			} else {
				this.$refs['subtitle-' + this.index][0].classList.add('text-overflow');
			}
		},

		async goToRoute() {
			setFullScreen();
      if (this.isNetworkOnline){
        MuseumsAPI.registerVisitRouteListV2(this.visitv2.idVisit, this.currentRoute.idRoute);
      }

			let name = (this.isExhibitionView ? "Exhibition" : "Route");
			debug.log("[" + (this.isExhibitionView ? "ExhibitionList" : "RouteList") + "] " + name + " " + this.currentRoute.idRoute + " was found");
			this.$router.push({
				name: name,
				params: { idMuseum: this.encrypted, idRoute: this.currentRoute.idRoute },
				query: {
					from: this.isExhibitionView ? "ExhibitionList" : "RouteList",
					...(this.$route.query.idRoute && { highlightedRoute: this.$route.query.idRoute })
				}
			});
		},
		
		goToRecognition() {
			setFullScreen();
			this.$router.push({name: 'Recognition', params: { idMuseum: this.encrypted} });
		},
		
		async goBack() {
			setFullScreen();
      if (this.isNetworkOnline){
        MuseumsAPI.updateVisitRouteListV2(this.visitv2.idVisit, this.currentRoute.idRoute);
        MuseumsAPI.updateVisitMuseumV2(this.visitv2, mutationTypes.SET_VISITV2, this.$store);
      }
			this.$router.push({ name: 'Options', params: { idMuseum: this.encrypted } });
		},

		getImageUrl(imageBlob) {
      if (imageBlob instanceof Blob) {
        return URL.createObjectURL(imageBlob);
      } else {
        return imageBlob;
      }
    },
	},
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.text-floating-mainImage {
	position:absolute;
	color: var(--text-color);
	bottom: 0;
	left:0;
	font-size: 12px; 
	width: 100%;
}
.content {
	height: 100%;
	
	header {
		position: fixed;
		top: 0;
		height: 70px;
		width: 100%;
		background-color: var(--bg-color);
		padding: 20px;
		z-index: 1;
		
		h1 {
			color: var(--text-color);
			font-size: 24px;
			font-weight: normal;
		}
	}
	
	.empty-route {
		background-color: white;
		padding: 80px 20px 100px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}
	
	.navigation {
		position: fixed;
		z-index: 1;
		top: 50%;
		height: 30px;
		width: auto;
		
		&.icon-previous-page {
			left: 10px;
		}
		
		&.icon-next-page {
			right: 10px;
		}
	}
	
	.VueCarousel {
		height: 100%;
		
		.route {
			height: 100%;
			
			.container {
				overflow: hidden;
				position: fixed;
				width: 100%;
				height: 100%;
				z-index: -1;
				
				.bg-image {
					width: 100%;
					height: 100%;
					filter: brightness(60%);
					object-fit: cover;
				}
			}
			
			main {
				padding: 10px 20px 200px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				overflow-y: scroll;
				
				.title {
					margin-bottom: 10px;
					color: white;
					
					h2 {
						overflow: hidden;
						color: white;
						font-size: 24px;
						font-weight: normal;
					}
					
					.read-more {
						text-decoration: underline;
					}
				}
				
				.subtitle {
					margin-bottom: 10px;
					color: white;
					
					h3 {
						overflow: hidden;
						color: white;
						font-size: 22px;
						font-weight: normal;
					}
					
					.read-more {
						text-decoration: underline;
					}
				}
				
				.text-overflow {
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				
				.info {
					display: flex;
					align-items: center;
					color: white;
					margin-bottom: 10px;
					
					i {
						margin-right: 10px;
						margin-bottom: -5px;
					}
				}
				
				.audio-container {
					flex: 2;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					.audio {
						width: 100%;
					}
				}
			}
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		
		.route-buttons {
			width: 100%;
			height: 90px;
			display: flex;
			align-items: center;
			
			.dots {
				width: 50%;
				padding: 10px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				
				.dot {
					border-radius: 100%;
					margin-right: 10px;
					width: 7px;
					height: 7px;
					background-color: #a2a2a2;
					margin-bottom: 10px;
					
					&.active {
						width: 10px;
						height: 10px;
						background-color: white;
					}
				}
			}
			
			.button-start {
				border: none;
				text-transform: uppercase;
				color: var(--text-color);
				background-color: var(--bg-color);
				padding: 10px;
				width: 50%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		
		.footer-buttons {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: center;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
				align-items: center;
				
				&.button-white {
					background-color: white;
					color: black;
				}
				
				&.button-recognition {
					color: white;
					background-color: var(--recognition-color);
					
					.recognition-icon {
						max-width: 60%;
						max-height: 80%;
					}
				}
			}
		}
	}
}
</style>
